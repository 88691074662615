import React, { useState } from "react";
import Clipboard from "react-clipboard.js";

export const Row = (props) => {
    const index = props.index;
    const identifierName = props.identifierName;
    const identifierType = props.identifierType;
    const identifierWords = props.identifierWords;

    const [copyButtonText, setCopyButtonText] = useState("copy");

    return (
        <tr
            className="py-4 ml-5 mb-5"
            style={{ marginLeft: "23px !important" }}
        >
            {/* <th class="text-muted align-middle px-2  text-center text-monospace font-weight-light" scope="row">
                <small>{index}</small>
            </th> */}
            <td className="align-middle ml-5">
                <kbd
                    className="font-weight-bold align-middle px-3 py-2 mr-4 pl-0 pr-2"
                    style={{ letterSpacing: "-0.01px" }}
                >
                    {identifierType === "function" && (
                        <span className="text-muted text-capitalize font-weight-normal pl-0"></span>
                    )}
                    {identifierName.replace("()", "")}
                    {(identifierType === "function" ||
                        identifierType === "test") && (
                        <span
                            style={{
                                position: "relative",
                                left: 1.5,
                                lineHeight: 0.5,
                                letterSpacing: "0.28px",
                                fontWeight: "600",
                            }}
                            className="text-muted text-monospace"
                        >
                            ()
                        </span>
                    )}
                    <span
                        className="pl-2 align-middle"
                        style={{
                            fontSize: "0.74rem",
                            position: "relative",
                            bottom: "0.0775rem",
                            left: "2.5px",
                            letterSpacing: "0.15px",
                            paddingRight: "2.5px",
                        }}
                    >
                        <samp>
                            <strong>
                                {identifierType === "class" && (
                                    <code className="code-type align-middle code-type-class font-weight-bold">
                                        CLASS
                                    </code>
                                )}
                                {identifierType === "function" && (
                                    <code className="code-type code-type-function font-weight-bold">
                                        {identifierType.toUpperCase()}
                                    </code>
                                )}
                                {identifierType === "variable" && (
                                    <code className="code-type code-type-variable font-weight-bold">
                                        {identifierType.toUpperCase()}
                                    </code>
                                )}
                                {identifierType === "constant" && (
                                    <code className="code-type code-type-constant font-weight-bold">
                                        {identifierType.toUpperCase()}
                                    </code>
                                )}
                                {identifierType === "test" && (
                                    <code className="code-type code-type-function font-weight-bold">
                                        {identifierType.toUpperCase()}
                                    </code>
                                )}
                            </strong>
                        </samp>
                    </span>
                </kbd>
            </td>
            <td className="text-right ml-5 align-middle">
                <div className="btn-group mr-1 dropleft">
                    {/* <button
                        type="button"
                        class="btn text-monospace mr-3 btn-light font-weight-bold dropdown-toggle btn-sm"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{border: '2px dashed #e7ecf2', borderRadius: 5}}
                    >
                        copy
                    </button> */}
                    <Clipboard
                        className="btn text-monospace btn-light align-text-bottom font-weight-bold dropdown-toggle btn-sm"
                        style={{
                            border: "2px dashed #e7ecf2",
                            borderRadius: 5,
                        }}
                        data-clipboard-text={identifierName.replace("()", "")}
                        button-title="copy to clipboard"
                        onClick={() => {
                            setCopyButtonText("copied!");
                            setTimeout(() => setCopyButtonText("copy"), 1375);
                        }}
                    >
                        {copyButtonText}
                    </Clipboard>
                    {/* <div
                        class="dropdown-menu"
                        x-placement="left-start"
                    >
                        <h6 class="dropdown-header">
                            <small>COPY DEFAULT:</small>
                        </h6>
                        <a class="dropdown-item" href="#">
                            <small>
                                <samp><mark>{identifierName}</mark></samp>
                            </small>
                        </a>
                        <div class="dropdown-divider"></div>
                        <h6 class="dropdown-header">
                            <small>COPY VARIANT:</small>
                        </h6>

                        <a class="dropdown-item" href="#">
                            <small>
                                <samp>exampleVariable</samp>
                            </small>
                        </a>
                        <a class="dropdown-item" href="#">
                            <small>
                                <samp>example_variable</samp>
                            </small>
                        </a>
                        <a class="dropdown-item" href="#">
                            <small>
                                <samp>EXAMPLE_VARIABLE</samp>
                            </small>
                        </a>
                        <a class="dropdown-item" href="#">
                            <small>
                                <samp>ExampleVariable</samp>
                            </small>
                        </a>
                        <a class="dropdown-item" href="#">
                            <small>
                                <samp>examplevariable</samp>
                            </small>
                        </a>
                        <a class="dropdown-item" href="#">
                            <small>
                                <samp>EXAMPLEVARIABLE</samp>
                            </small>
                        </a>
                        <a class="dropdown-item" href="#">
                            <small>
                                <samp>example-variable</samp>
                            </small>
                        </a>
                        <a class="dropdown-item" href="#">
                            <small>
                                <samp>EXAMPLE-VARIABLE</samp>
                            </small>
                        </a>
                        <a class="dropdown-item" href="#">
                            <small>
                                <samp>Example_Variable</samp>
                            </small>
                        </a>
                        <a class="dropdown-item" href="#">
                            <small>
                                <samp>_exampleVariable</samp>
                            </small>
                        </a>
                        <a class="dropdown-item" href="#">
                            <small>
                                <samp>_ExampleVariable</samp>
                            </small>
                        </a>
                        <a class="dropdown-item" href="#">
                            <small>
                                <samp>__exampleVariable</samp>
                            </small>
                        </a>
                        <a class="dropdown-item" href="#">
                            <small>
                                <samp>@exampleVariable</samp>
                            </small>
                        </a>
                        <a class="dropdown-item" href="#">
                            <small>
                                <samp>$exampleVariable</samp>
                            </small>
                        </a>
                        <div class="dropdown-divider"></div>
                        <h6 class="dropdown-header">
                            <small>COPY FOR LANGUAGE:</small>
                        </h6>

                        <a class="dropdown-item" href="#">
                            <small>
                                <samp>Java</samp>
                            </small>
                        </a>
                        <a class="dropdown-item" href="#">
                            <small>
                                <samp>C</samp>
                            </small>
                        </a>
                        <a class="dropdown-item" href="#">
                            <small>
                                <samp>C++</samp>
                            </small>
                        </a>
                        <a class="dropdown-item" href="#">
                            <small>
                                <samp>Python</samp>
                            </small>
                        </a>
                        <a class="dropdown-item" href="#">
                            <small>
                                <samp>Visual Basic .NET</samp>
                            </small>
                        </a>
                    </div> */}
                </div>
            </td>
        </tr>
    );
};

// Row.propTypes

// Row.propTypes = {
//   index: React.PropTypes.number.isRequired,
//   identifierName: React.PropTypes.string.isRequired,
//   identifierType: React.PropTypes.string.isRequired,
//   identifierWords: React.PropTypes.array.isRequired
// };
