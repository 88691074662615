import React from 'react';
import PackageJson from '../package.json';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import SearchInput from './components/SearchInput';
import LogoPNG from './variable-names-logo-icon.png';

import './App.css';

function App() {
  return (
    <div className="container-fluid App">
      <div className="row mt-1">
        {/* <div className="col-lg-3 text-left mt-4">
          <h1 className="text-monospace h2 font-weight-bolder" style={{letterSpacing: '-2.1px'}}>
            <span style={{color: '#FE653B'}}>Variable</span>Names
          </h1>
          <h6 className="text-monospace text-muted pt-0 pl-1" style={{letterSpacing: '0.1px'}}>
            <small>A THESAURUS BUILT FOR CODERS</small>
          </h6>
        </div> */}
        <div className="mx-auto col-sm-8 col-md-9 col-lg-8 col-xl-6">
          <SearchInput />
        </div>
      </div>
      <div className="text-muted text-monospace" style={{position: 'absolute', top: 8.5, right: 13}}>
        <span className="badge badge-light text-muted">
          <a href="https://github.com/justinryanleung/VariableNames.com" target="_blank" rel="noopener noreferrer" style={{color: 'inherit', textDecoration: 'inherit', borderBottom: '2px dotted #d5e0e0'}}>
            v{PackageJson.version}
          </a>
        </span>
      </div>
      <div className="text-muted text-monospace" style={{position: 'absolute', top: 0, left: 14}}>
        <img style={{height: 50}} src={LogoPNG} alt="logo" />
      </div>
    </div>
  );
}

export default App;
