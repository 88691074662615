import MiniSearch from "minisearch";
import React from "react";
import { Row } from "./Row";
var synonyms = require("synonyms");

/**
 * Validates: `PascalCase`
 * Tests: @link https://regex101.com/r/2C6aQK/3
 */
const VALID_PASCAL_CASE = /^(?!EE$)^(?!II$)(?!^.*[A-DF-HJ-Z]{2,}.*$)^([A-Z][A-Za-z0-9]*)$/;

/**
 * Validates: `camelCaseFunction(), PascalCaseFunction()`
 * Tests: @link https://regex101.com/r/OY6K2M/2
 */
const VALID_CAMEL_CASE = /(?!^.*[A-Z]{2,}.*$)^([A-Za-z][A-Za-z0-9]*)$/;

const STOP_WORDS = new Set(["and", "self", "this", "a"]);

const identifiers = require("../data/identifiers.json");

/**
 *
 */
function getRelatedWords (words)
{
    var relatedWords = []
    words = words.split(' ')
    words.forEach(synonymOriginWord => {
        if (synonymOriginWord.length < 2) {
            return;
        }

        var verbs = []
        verbs = synonyms(synonymOriginWord, "v")
        if (typeof verbs !== 'undefined') {
            verbs = verbs.filter(verb => {
                if (verb === synonymOriginWord) {
                    return false
                }

                if (verb.length < 2 || verb.length > 8) {
                    return false
                }

                return verb
            });
            verbs.length = Math.min(verbs.length, 2)
            verbs.forEach(verb => {
                const foundWord = relatedWords.find(word => word === verb)
                if (foundWord) {
                    return;
                }
                relatedWords.push(verb)
            });
        } else {
            verbs = []
        }

        var nouns = []
        nouns = synonyms(synonymOriginWord, "n")

        if (typeof nouns !== 'undefined') {
            nouns = nouns.filter(noun => {
                if (noun === synonymOriginWord) {
                    return false
                }

                if (noun.length < 2 || noun.length > 9) {
                    return false
                }

                return noun
            });
            nouns.length = Math.min(nouns.length, 2)

            nouns.forEach(noun => {
                const foundWord = relatedWords.find(word => word === noun)
                if (foundWord) {
                    return;
                }
                relatedWords.push(noun)
            });
        } else {
            nouns = []
        }
        // console.log(nouns);
        // console.log(verbs);

        // var temp = [...verbs, ...nouns]; /*?*/
        // console.log([...temp, ...relatedWords]);
        // Array.from(new Set(temp)) /*?*/
    })
    // console.log(relatedWords);
    // relatedWords.length = Math.min(relatedWords.length, 6);
    return relatedWords.join(' ')
}

var vn = new MiniSearch({
    fields: ["type", "name", "words", "relatedWords"], // fields to index for full-text search
    storeFields: ["type", "name", "words", "relatedWords"], // fields to return with search results
    idField: 'name',
    searchOptions: {
        boost: { identifier: 10, words: 5.5, relatedWords: 3.5 },
        fuzzy: 0.2,
    },
    tokenize: (string) =>
        string
            .split(/\s+/)
            .filter((word) => !STOP_WORDS.has(word.toLowerCase())),
});
vn.addAll(identifiers);

// TODO: replace words that end with ing if no results were found
// TODO: set combineWith value to OR if no results were found or length is longer than 4
// TODO: a function that runs if multiple words find no names and it checks word permutations then sorts those results by score
// TODO: Add a dels ins feature that highlights words that have matched or didn't match. On hover, a message like Grammarly will respond with suggestions. See: https://codemyui.com/insert-with-a-green-background-and-delete-with-reveal-on-hover/
class SearchInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userInput: "",
            queryInput: "",
            searchResults: [],
            searchResultsNonResized: [],
            orderBy: "relevancy",
            categoryFilter: "any",
            resultsFilter: 25,
            searchMatchFilter: "narrow",
            hasUpdatedSettingResults: true
        };

        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.handleResultsChange = this.handleResultsChange.bind(this);
        this.handleUserInput = this.handleUserInput.bind(this);
        this.handleSearchMatchChange = this.handleSearchMatchChange.bind(this);
        this.handleApplyChanges = this.handleApplyChanges.bind(this);
    }

    handleSortChange(e) {
        const orderBy = e.target.value;
        this.setState({ orderBy: orderBy, hasUpdatedSettingResults: false });
    }

    handleCategoryChange(e) {
        const category = e.target.value;
        this.setState({ categoryFilter: category, hasUpdatedSettingResults: false });
    }

    handleResultsChange(e) {
        const resultsValue = parseInt(e.target.value, 10);
        this.setState({ resultsFilter: resultsValue, hasUpdatedSettingResults: false });
    }

    handleApplyChanges(e) {
        const { queryInput, results } = this.invokeSearchQuery(this.state.queryInput);
        this.setState({ queryInput: queryInput, searchResults: results });
        e.preventDefault();
    }

    handleSearchMatchChange(e) {
        const match = e.target.value;
        this.setState({ searchMatchFilter: match });
    }

    invokeSearchQuery(queryString) {
        let queryInput = queryString.trim();
        let words = queryInput.split(" ");

        const searchQueryWords = words.map((word) => {
            if (VALID_PASCAL_CASE.test(word)) {
                return word
                    .match(/([A-Z]?[^A-Z]*)/g)
                    .slice(0, -1)
                    .map((w) => w.toLowerCase())
                    .join(" ");
            }

            if (VALID_CAMEL_CASE.test(word)) {
                return word
                    .match(/([A-Z]?[^A-Z]*)/g)
                    .slice(0, -1)
                    .map((term) => term.toLowerCase())
                    .join(" ");
            }

            return word;
        });

        let query = searchQueryWords.join(" ").toLowerCase().replace("()", "");

        // , fuzzy: term => term.length > 2 ? 0.2 : null, prefix: term => term.length >= 2
        var combineWith = "AND";
        var isPrefixed = true;

        if (this.state.searchMatchFilter === "broad") {
            isPrefixed = false;
        } else if (this.state.searchMatchFilter === "exact") {
            // console.log(this.state.searchMatchFilter);
            combineWith = "AND";
        } else if (this.state.searchMatchFilter === "narrow") {
            combineWith = "OR";
        }

        // if (query.split(' ').length > 3) {
        //     combineWith = 'OR';
        // }

        var searchResults;
        // var queryRelatedWords = getRelatedWords(query)
        var queryRelatedWords = ''
        // console.log(queryRelatedWords);
        if (this.state.categoryFilter !== "any") {
            searchResults = vn.search(`${query} ${queryRelatedWords}`, {
                filter: (result) => result.type === this.state.categoryFilter,
                combineWith,
                prefix: isPrefixed,
            });
            // console.log(searchResults);
        } else {
            searchResults = vn.search(`${query} ${queryRelatedWords}`, {
                combineWith: combineWith,
                prefix: isPrefixed,
            });
            // console.log(searchResults);
        }

        // let extraSearchResults;
        // console.log(query);
        // extraSearchResults = vn.search(query, {
        //     combineWith: 'AND',
        //     prefix: isPrefixed,
        // });
        // console.log(extraSearchResults.length);

        // console.log(extraSearchResults.length);

        // extraSearchResults = extraSearchResults.map(newResultItem => {
        //     const found = searchResults.find(existingResultItem => existingResultItem.name !== newResultItem.name);
        //     if (found) {
        //         return newResultItem.name;
        //     }
        // });

        // console.log(extraSearchResults.length);

        // let extraSearchResults;
        // if (combineWith === 'OR' && searchResults.length < 5) {
        //     combineWith = 'AND';
        //     extraSearchResults = vn.search(query, {
        //         filter: (result) => result.type === this.state.categoryFilter,
        //         combineWith,
        //         prefix: isPrefixed,
        //     });

        //     extraSearchResults = extraSearchResults.map(newResultItem => {
        //         const found = searchResults.find(existingResultItem => existingResultItem.name !== newResultItem.name);
        //         if (found) {
        //             return newResultItem.name;
        //         }
        //     });

        //     console.log(extraSearchResults.length);

            // var resultsWithoutDuplicates = extraSearchResults.map((searchResultItem) => {
            //     console.log(searchResultItem);
            //     return searchResultItem;
            // });
            // array1.concat(array2)
        // }

        // if (results.length === 0 && query.split(' ').length === 2) {
        //     combineWith = 'OR';

        //     if (this.state.categoryFilter !== 'any')
        //     {
        //         results = vn.search(query, { filter: (result) => result.type === this.state.categoryFilter, combineWith, prefix: isPrefixed });
        //     }
        //     else
        //     {
        //         results = vn.search(query, { combineWith: combineWith, prefix: isPrefixed });
        //     }
        // }

        searchResults.length = Math.min(searchResults.length, this.state.resultsFilter);

        // Apply order by sorts on the search results array.
        if (this.state.orderBy === "relevancy") {
            this.setState({ queryInput: queryInput, searchResults: searchResults });
        } else if (this.state.orderBy === "a-z") {
            searchResults = sortByAttribute(searchResults, "name");
        } else if (this.state.orderBy === "z-a") {
            searchResults = sortByAttribute(searchResults, "name");
            searchResults.reverse();
        } else if (this.state.orderBy === "shortest-longest") {
            searchResults.sort(sortMultiDimensional);
        } else if (this.state.orderBy === "longest-shortest") {
            searchResults.sort(sortMultiDimensional);
            searchResults.reverse();
        }

        return { queryInput, results: searchResults };
    }

    handleUserInput(e) {
        let queryString = this.state.userInput;
        queryString = e.target.value;

        this.setState({ userInput: e.target.value });
        const { queryInput, results } = this.invokeSearchQuery(queryString);

        // results.length = Math.min(results.length, this.state.resultsFilter);

        this.setState({
            queryInput: queryInput,
            searchResults: results,
            hasUpdatedSettingResults: true
        });
    }

    render() {
        // const hasNoResults = (this.state.searchResults === [] && this.state.queryInput !== '');
        const hasNoResults =
            this.state.searchResults.length === 0 &&
            this.state.queryInput.length > 0;

        // let results = this.state.searchResults;
        // results.length = Math.min(results.length, this.state.resultsFilter);

        return (
            <div>
                {/* {!hasResults && <div className="mt-3 ml-3 text-monospace text-left text-muted">
                    Find better variable names for your codes variables!
                </div>} */}
                <div
                    className="mt-5 pt-2 text-muted ml-2 text-left"
                    style={{
                        letterSpacing: "-.1975px",
                        paddingLeft: 0.5,
                        paddingBottom: 1,
                    }}
                >
                    {/* <small><strong><samp>Find name suggestions for your code:</samp></strong></small> */}
                    <small>
                        <strong>
                            <samp>
                                {/* Visible only on xs, sm */}
                                <span className="d-block d-sm-none d-none d-sm-block d-md-none">
                                    {/* Discover names to improve code readability: */}
                                    {/* Make your code readable. Find better names: */}
                                    Find names to make your code more readable:
                                </span>
                                {/* Hidden only on xs, sm */}
                                <span className="d-none d-sm-block d-sm-none d-md-block">
                                    {/* Discover names to improve code readability: */}
                                    {/* Make code more readable. Find and use better names: */}
                                    {/* Make your code readable. Find and use better names: */}
                                    {/* Make code easy-to-understand; find & use expressive names: */}
                                    {/* Find expressive names to help write self-explanatory code: */}
                                    {/* Write self-explanatory code; find & use more descriptive names: */}
                                    {/* Find expressive names to write code that's self-explanatory: */}
                                    {/* OLD: Find descriptive names to help write self-explanatory code: */}
                                    Find descriptive names that help write self-explanatory code:
                                    {/* Find descriptive names for writing self-explanatory code: */}
                                    {/* Find expressive names to use. Make your code self-explanatory: */}
                                    {/* Make your code understandable, find better names to use: */}
                                    {/* Find names to Make your code more  the readability of your code: */}
                                </span>
                            </samp>
                        </strong>
                    </small>
                </div>
                <div className="input-group mt-1 mb-1">
                    <input
                        id="search-input"
                        type="text"
                        value={this.state.userInput}
                        onChange={this.handleUserInput}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="on"
                        className={`form-control text-monospace pr-5 form-control-lg ${
                            hasNoResults ? "form-control-no-results" : ""
                        }`}
                        style={{
                            letterSpacing: "-0.23px",
                            zIndex: 0,
                            fontWeight: "600",
                            borderRadius: "0.557rem",
                            backgroundColor: "#fafbfc",
                        }}
                        autoFocus
                        placeholder="keywords, verbs, nouns, identifiers..."
                        aria-label="Recipient's username"
                        aria-describedby="button-addon2"
                    />
                </div>
                <div className="text-right" style={{ height: 0 }}>
                    <button
                        className="btn dropdown-toggle"
                        style={{ letterSpacing: "-0.5px" }}
                        type="button"
                        data-toggle="modal"
                        data-target="#exampleModal"
                    >
                        {/* <svg style={{position: 'absolute', height: 28, fill: 'rgb(255 128 97)', right: 11.5, bottom: 9}} viewBox="0 0 24 24"><path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path></svg> */}
                        {/* <svg style={{position: 'absolute', height: 28, right: 10.5, bottom: 9}} width="24" height="24" viewBox="0 0 24 24" className="Icon nav-settings" xmlns="http://www.w3.org/2000/svg"><path fill="#fe653c" d="M14.071 1.25a.75.75 0 0 1 .72.538l.802 2.72c.45.204.877.445 1.283.724l2.875-.703a.75.75 0 0 1 .821.343l2.071 3.457a.75.75 0 0 1-.114.917l-2.05 2.043a7.018 7.018 0 0 1 .001 1.409l2.05 2.042a.75.75 0 0 1 .113.917l-2.07 3.457a.75.75 0 0 1-.822.343l-2.876-.704a8.822 8.822 0 0 1-1.28.723l-.804 2.735a.75.75 0 0 1-.72.539H9.929a.75.75 0 0 1-.72-.538l-.802-2.72a8.391 8.391 0 0 1-1.283-.724l-2.875.703a.75.75 0 0 1-.821-.343l-2.071-3.457a.75.75 0 0 1 .114-.917l2.05-2.043A7.265 7.265 0 0 1 3.483 12c0-.227.012-.46.036-.698L1.47 9.26a.75.75 0 0 1-.113-.917l2.07-3.457a.75.75 0 0 1 .822-.343l2.876.704a8.822 8.822 0 0 1 1.28-.723L9.21 1.79a.75.75 0 0 1 .72-.539h4.142zm-3.58 1.5l-.745 2.533a.75.75 0 0 1-.433.481c-.578.24-1.112.54-1.582.893a.75.75 0 0 1-.628.129L4.43 6.13 2.945 8.61l1.892 1.886a.75.75 0 0 1 .213.636c-.043.31-.066.598-.066.867 0 .268.022.555.067.885a.75.75 0 0 1-.214.632l-1.892 1.886 1.485 2.48 2.673-.654a.75.75 0 0 1 .628.128c.487.366 1.008.66 1.577.89a.75.75 0 0 1 .438.484l.744 2.519h3.02l.744-2.533a.75.75 0 0 1 .433-.481 7.251 7.251 0 0 0 1.582-.893.75.75 0 0 1 .628-.129l2.673.655 1.485-2.48-1.892-1.886a.75.75 0 0 1-.213-.636c.043-.31.066-.598.066-.867a6.52 6.52 0 0 0-.067-.885.75.75 0 0 1 .214-.632l1.892-1.886-1.485-2.48-2.673.654a.75.75 0 0 1-.628-.128 6.923 6.923 0 0 0-1.577-.89.75.75 0 0 1-.438-.484L13.51 2.75h-3.02zM12 8.25c2.075 0 3.772 1.685 3.772 3.75S14.075 15.75 12 15.75c-2.075 0-3.772-1.685-3.772-3.75S9.925 8.25 12 8.25zm0 1.5c-1.249 0-2.272 1.015-2.272 2.25s1.023 2.25 2.272 2.25c1.249 0 2.272-1.015 2.272-2.25S13.249 9.75 12 9.75z" fillRule="evenodd"></path></svg> */}
                        <svg
                            style={{
                                position: "relative",
                                fill: "rgb(166 178 187)",
                                right: -15.5,
                                zIndex: 1,
                                top: -47,
                            }}
                            version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            height="26.75"
                            width="26"
                            viewBox="0 0 32 32"
                            className={`nav-icon tool-icon gear-icon ${
                                hasNoResults ? "colortest" : ""
                            }`}
                            xmlSpace="preserve"
                        >
                            <path d="M4.8,16.5h2.4c0.2,0.6,0.4,1.3,0.8,1.9l-1.7,1.7c-0.8,0.8-0.8,2,0,2.8c0,0,0,0,0,0L7,23.7c0.8,0.8,2,0.8,2.8,0 c0,0,0,0,0,0l1.7-1.7c0.6,0.3,1.2,0.6,1.9,0.8v2.4c0,1.1,0.9,2,2,2h1c1.1,0,2-0.9,2-2v-2.4c0.7-0.2,1.3-0.4,2-0.8l1.7,1.7 c0.8,0.8,2,0.8,2.8,0c0,0,0,0,0,0l0.7-0.7c0.8-0.8,0.8-2,0-2.8c0,0,0,0,0,0L24,18.4c0.3-0.6,0.6-1.2,0.8-1.9h2.4c1.1,0,2-0.9,2-2v-1 c0-1.1-0.9-2-2-2h-2.4c-0.2-0.7-0.4-1.3-0.8-1.9l1.7-1.7c0.8-0.8,0.8-2,0-2.8c0,0,0,0,0,0L25,4.3c-0.8-0.8-2-0.8-2.8,0c0,0,0,0,0,0 L20.4,6c-0.6-0.3-1.2-0.6-1.9-0.8V2.8c0-1.1-0.9-2-2-2h-1c-1.1,0-2,0.9-2,2v2.4c-0.7,0.2-1.3,0.4-1.9,0.8L9.9,4.3 c-0.8-0.8-2-0.8-2.8-0.1c0,0,0,0-0.1,0.1L6.3,5c-0.8,0.8-0.8,2,0,2.8c0,0,0,0,0,0L8,9.6c-0.3,0.6-0.6,1.2-0.8,1.9H4.8 c-1.1,0-2,0.9-2,2v1C2.8,15.6,3.7,16.5,4.8,16.5C4.8,16.5,4.8,16.5,4.8,16.5z M10.1,12.5c0.1-0.2,0.1-0.4,0.2-0.6 c0.1-0.1,0.1-0.3,0.2-0.4c0.6-1.3,1.7-2.4,3-3l0.4-0.2c0.5-0.2,1.1-0.3,1.6-0.4H16h0.5c0.6,0,1.1,0.2,1.6,0.4l0.4,0.2 c1.3,0.6,2.4,1.7,3,3c0.1,0.1,0.1,0.3,0.2,0.4c0.2,0.5,0.3,1.1,0.4,1.6c0,0.2,0,0.3,0,0.5s0,0.3,0,0.5c0,0.6-0.2,1.1-0.4,1.6 c0,0.1-0.1,0.3-0.2,0.4c-0.6,1.3-1.7,2.4-3,3l-0.4,0.2c-0.5,0.2-1.1,0.3-1.6,0.4H16h-0.5c-0.6,0-1.1-0.2-1.6-0.4l-0.4-0.2 c-1.3-0.6-2.4-1.7-3-3c-0.1-0.1-0.1-0.3-0.2-0.4c-0.2-0.5-0.3-1.1-0.4-1.6c0-0.2,0-0.3,0-0.5s0-0.3,0-0.5C10,13.1,10,12.8,10.1,12.5 z" />
                        </svg>
                        {/* #fe653c */}
                    </button>
                </div>
                {/* <p>{this.state.userInput}</p> */}
                {/* <div className="mt-4"> */}
                {/* <section className="input-group mb-3">
                <input type="text" value={this.state.userInput} onChange={this.handleUserInput} className="form-control form-control-lg" placeholder="Search topics, keywords, etc..." aria-label="Recipient's username" aria-describedby="button-addon2" />
                <div className="input-group-append">
                    <button className="btn btn-light" type="button" id="button-addon2">search</button>
                </div>
            </section> */}
                {/* </div> */}

                {/* { this.state.searchResults.length > 0 && <div className="mt-3 ml-3 text-muted text-left">{this.state.searchResults.length} names found</div> } */}

                {this.state.searchResults.length === 0 &&
                    this.state.queryInput.length > 0 && (
                        <div className="my-1 ml-2 text-uppercase text-right font-weight-bold text-monospace text-muted fade-in">
                            {/* rgb(137 145 151) */}
                            <span
                                className="rounded py-1"
                                style={{
                                    letterSpacing: "0.67px",
                                    marginTop: 18,
                                }}
                            >
                                <code style={{ color: "" }}>
                                    &nbsp;
                                    <small>
                                        <b style={{ color: "rgb(240 113 79)" }}>
                                            {/* Try to adjust your search settings */}
                                            {/* Try playing with the search settings */}
                                            ADJUST YOUR SEARCH OPTIONS
                                            {/* Try using our search settings. */}
                                            {/* ADJUST YOUR SEARCH SETTINGS */}
                                            {/* TRY USING OUR SEARCH FILTERS. */}
                                        </b>
                                        &nbsp;
                                    </small>
                                </code>
                            </span>
                        </div>
                    )}
                <table
                    style={{ marginTop: "0.825rem" }}
                    className="table mb-3 table-borderless table-sm table-responsive-sm table-striped text-left"
                >
                    <thead className="d-none">
                        <tr>
                            {/* <th scope="col"></th> */}
                            <th scope="col">Identifier Name</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.searchResults.map((identifier, index) => (
                            <Row
                                key={index}
                                index={index + 1}
                                identifierName={identifier.name}
                                identifierType={identifier.type}
                                identifierWords={identifier.words}
                            />
                        ))}
                        {/* <Row index="0" identifierName="fuck" identifierType="function" identifierTerms={[]} /> */}
                    </tbody>
                </table>
                <div
                    className="modal p-4 fade"
                    id="exampleModal"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4
                                    className="text-monospace modal-title font-weight-bolder"
                                    style={{ letterSpacing: "-3.5px !important", paddingLeft: 2.5 }}
                                    id="exampleModalLabel"
                                >
                                    Search Options
                                </h4>
                                {/* <button type="button" onClick={this.handleApplyChanges} className="text-uppercase btn mx-auto w-100 btn-sm btn-dark font-weight-bold" data-dismiss="modal" aria-label="Close">
                                            Apply To View Changes
                                        </button> */}
                                <button
                                    type="button"
                                    className="close pr-3"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body px-3 pt-3 pb-1 text-monospace">
                                <form>
                                        <div className="form-group row">
                                        {/* <label for="inputPassword" className="col-sm-3 pl-4 text-left col-form-label">
        <samp><code>SORT BY:</code></samp>
    </label> */}
                                        <label className="col-sm-1 pl-3 font-weight-bold text-left col-form-label">
                                            <samp>
                                                <kbd className="px-2">
                                                    SORT:
                                                </kbd>
                                            </samp>
                                        </label>
                                        <div className="col-sm-10 offset-sm-1">
                                            <select
                                                className="custom-select"
                                                onChange={
                                                    this.handleSortChange
                                                }
                                            >
                                                <option defaultValue="relevancy">
                                                    {/* Best Match */}
                                                    Most Relevant → Least Relevant
                                                </option>
                                                <option value="shortest-longest">
                                                    Shortest → Longest
                                                </option>
                                                <option value="longest-shortest">
                                                    Longest → Shortest
                                                </option>
                                                {/* <option value="a-z">
                                                    A - Z
                                                </option>
                                                <option value="z-a">
                                                    Z - A
                                                </option> */}
                                            </select>
                                        </div>
                                    </div>

                                    {/* <hr/> */}

                                    <div className="form-group row">
                                        {/* <label for="inputPassword" className="col-sm-3 pl-4 text-left col-form-label">
        <samp><code>CATEGORY:</code></samp>
    </label> */}
                                        <label className="col-sm-1 pl-3 font-weight-bold text-left col-form-label">
                                            <samp>
                                                <kbd className="px-2">
                                                    LIST:
                                                </kbd>
                                            </samp>
                                        </label>
                                        <div className="col-sm-10 offset-sm-1">
                                            <select
                                                className="custom-select"
                                                onChange={
                                                    this.handleCategoryChange
                                                }
                                            >
                                                <option defaultValue="any">
                                                    All Types
                                                </option>
                                                <option value="variable">
                                                    Variables
                                                </option>
                                                <option value="function">
                                                    Functions
                                                </option>
                                                <option value="constant">
                                                    Constants
                                                </option>
                                                <option value="class">
                                                    Classes
                                                </option>
                                                <option value="test">
                                                    Test Functions
                                                </option>
                                                <option disabled value="test">
                                                    Endpoints - In Progress
                                                </option>
                                                <option disabled value="test">
                                                    UI Elements - Coming Soon
                                                </option>
                                                <option disabled value="test">
                                                    CSS Classes - Coming Soon
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    {/* <hr/> */}

                                    <div className="form-group row">
                                        <label className="col-sm-1 pl-3 font-weight-bold text-left col-form-label">
                                            <samp>
                                                <kbd className="px-2">
                                                    SHOW:
                                                </kbd>
                                            </samp>
                                        </label>
                                        <div className="col-sm-10 offset-sm-1">
                                            <select
                                                className="custom-select"
                                                onChange={
                                                    this.handleResultsChange
                                                }
                                            >
                                                <option defaultValue="25">
                                                    25 Names
                                                </option>
                                                {/* <option value="30">30 Suggestions</option> */}
                                                <option value="50">
                                                    50 Names
                                                </option>
                                                <option value="75">
                                                    75 Names - (Warning: Slow)
                                                </option>
                                                {/* <option value="50">100 Suggestions - (Warning: Very Slow)</option> */}
                                            </select>
                                        </div>
                                    </div>

                                    {/* <hr/> */}

                                    {/* <div className="form-group row">
                                        <label className="col-sm-1 pl-3 font-weight-bold text-left col-form-label">
                                            <samp>
                                                <kbd className="px-2">
                                                    FIND:
                                                </kbd>
                                            </samp>
                                        </label>
                                        <div className="col-sm-10 offset-sm-1">
                                            <select
                                                className="custom-select"
                                                onChange={
                                                    this.handleSearchMatchChange
                                                }
                                            >
                                                <option defaultValue="narrow">
                                                    Related & Similar Names -
                                                    (best for finding names)
                                                </option>
                                                <option value="exact">
                                                    Exact Match Names - (must contain all of the input keywords)
                                                </option>
                                                <option value="broad">
                                                    Discovery Type Names - (large
                                                    search results range)
                                                </option>
                                            </select>
                                        </div>
                                    </div> */}

                                    <hr />

                                    <div className="form-group mb-2 pb-1 row mx-1 pt-0">
                                        <button type="button" style={{letterSpacing: '0.4px !important'}} onClick={this.handleApplyChanges} className="text-uppercase btn mx-auto w-100 btn-sm btn-warning font-weight-bold" data-dismiss="modal" aria-label="Close">
                                            {/* update your results */}
                                            {/* Click to update results → */}
                                            Update results
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function sortByAttribute(array, ...attrs) {
    // generate an array of predicate-objects contains
    // property getter, and descending indicator
    let predicates = attrs.map((pred) => {
        let descending = pred.charAt(0) === "-" ? -1 : 1;
        pred = pred.replace(/^-/, "");
        return {
            getter: (o) => o[pred],
            descend: descending,
        };
    });
    // schwartzian transform idiom implementation. aka: "decorate-sort-undecorate"
    return array
        .map((item) => {
            return {
                src: item,
                compareValues: predicates.map((predicate) =>
                    predicate.getter(item)
                ),
            };
        })
        .sort((o1, o2) => {
            let i = -1,
                result = 0;
            while (++i < predicates.length) {
                if (o1.compareValues[i] < o2.compareValues[i]) result = -1;
                if (o1.compareValues[i] > o2.compareValues[i]) result = 1;
                if ((result *= predicates[i].descend)) break;
            }
            return result;
        })
        .map((item) => item.src);
}

function sortMultiDimensional(a, b) {
    return a.name.length < b.name.length
        ? -1
        : a.name.length > b.name.length
        ? 1
        : 0;
}

export default SearchInput;
